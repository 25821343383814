import * as React from 'react'

const body = document.getElementsByTagName('body')[0]

export function useHidePendo(hide: boolean) {
  React.useEffect(() => {
    if (hide) {
      body.classList.add('hide-pendo')

      return () => {
        body.classList.remove('hide-pendo')
      }
    }
  }, [hide])
}
