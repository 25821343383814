import { useState, useEffect } from 'react'

const usePortalEl = () => {
  const [node] = useState<HTMLDivElement>(() => {
    const el = document.createElement('div')
    el.setAttribute('data-ec-settings-nav', '') //produces <div data-ec-settings-nav> to ensure tailwind styles apply correctly
    document.body.appendChild(el)
    return el
  })

  useEffect(() => {
    return () => {
      try {
        document.body.removeChild(node)
      } catch (error) {
        console.log(error, node)
      }
    }
  }, [node])

  return { node }
}

export { usePortalEl }
