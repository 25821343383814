import { ApolloClient, InMemoryCache } from '@apollo/client'
import { QueryClient } from '@tanstack/react-query'
import { StrictTypedTypePolicies } from './generated/helpers'

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {} as StrictTypedTypePolicies
  }),
  uri: '/graphql'
})

export const rqClient = new QueryClient()
