import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import cx from 'classnames'
import { useCombobox } from 'downshift'

import { CardContainer } from '@toasttab/buffet-pui-card'
import { SearchInput } from '@toasttab/buffet-pui-text-input'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

import { Portal, PortalProvider } from '@toasttab/ec-layout'
import { useCompanyCode } from '@toasttab/ec-session'

import { Header, PageTemplateProvider } from './header'

import { RouteObject } from 'react-router-dom'
import { useEcBanquetProps } from '@toasttab/ec-banquet-props'
import {
  SecondaryNavItem,
  MobileSecondaryNav,
  SecondaryNavSection
} from '@toasttab/buffet-pui-navigation'
import { useIsMobile } from '../../hooks/useIsMobile'
import { usePortalEl } from '../../hooks/usePortalNode'
import HidePendoBadge from '../routes/HidePendoBadge'

export const EntryRoute: RouteObject = {
  path: '*',
  element: <App />
}

function App() {
  const isMobile = useIsMobile()

  return (
    <PageTemplateProvider
      options={{
        page: {
          container: ''
        },
        header: {
          sticky: false,
          verticalOffsetValue: '0px'
        }
      }}
    >
      <PortalProvider value='data-ec-settings-nav'>
        <Portal
          id='page-header'
          component={
            <>
              <Header title='Settings' />
              {isMobile && (
                <div className='pb-4'>
                  <CompactMenu />
                </div>
              )}
            </>
          }
        />
        {!isMobile && (
          <Portal
            id='page-sidebar'
            skipRenderIfNotFound={false}
            component={<Menu />}
          />
        )}
      </PortalProvider>
    </PageTemplateProvider>
  )
}

export type MenuModel = {
  name: string
  searchName: string
  sequence: number
  pagePath: string
  isOnDashboard: boolean
  pageUrl: string
  _active: boolean
  onMenu: boolean
  page: string
}

const useNavigation = () => {
  const companyCode = useCompanyCode()
  return useQuery<MenuModel[]>(['navigation'], () =>
    fetch(`/mvc/${companyCode}/Company/Setup/Navigation`).then((x) => x.json())
  )
}

function getMenuFilter(inputValue: string) {
  const lowerCasedInputValue = inputValue.toLowerCase()

  return function filter(item: MenuModel) {
    return (
      !inputValue ||
      item.searchName.toLowerCase().includes(lowerCasedInputValue)
    )
  }
}

function Search({ initialItems }: { initialItems: MenuModel[] }) {
  const [items, setItems] = React.useState<MenuModel[]>(initialItems)
  const {
    ec: { ecNavigate }
  } = useEcBanquetProps()

  const {
    isOpen,
    getInputProps,
    highlightedIndex,
    getItemProps,
    getMenuProps,
    selectedItem
  } = useCombobox({
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem?.pageUrl) {
        ecNavigate(selectedItem.pageUrl)
      }
    },
    onInputValueChange: ({ inputValue }) =>
      setItems(initialItems.filter(getMenuFilter(inputValue ?? ''))),
    items,
    itemToString(item) {
      return item?.name ?? ''
    }
  })

  return (
    <div className='relative'>
      <SearchInput placeholder='Search...' {...getInputProps()} />
      <div {...getMenuProps()}>
        {isOpen && items.length > 0 && (
          <CardContainer
            tabIndex={0}
            noPadding={true}
            className='py-2 mt-1 flex flex-col w-full overflow-auto divide-y'
            style={{
              maxHeight: '500px'
            }}
          >
            {items.map((item, index) => {
              const [category, section = category] = item.searchName.split('-')

              return (
                <a
                  key={`${item.searchName}-${index}`}
                  href={item.pageUrl}
                  className={cx('text-default type-default w-full px-3 py-3', {
                    'bg-darken-4': highlightedIndex === index,
                    border: selectedItem === item
                  })}
                  {...getItemProps({ item, index })}
                >
                  <span className='text-secondary type-caption'>
                    {category}
                  </span>
                  <div>{section}</div>
                </a>
              )
            })}
          </CardContainer>
        )}
      </div>
    </div>
  )
}

export function List({ initialItems }: { initialItems: MenuModel[] }) {
  const items = initialItems.filter((item) => item.onMenu)

  return (
    <>
      {items?.map((item) => (
        <a
          key={item.pagePath}
          href={item.pagePath}
          className={cx(
            'py-2 px-4 rounded-md font-sans text-default type-default',
            {
              'bg-brand-0': item._active,
              'hover:bg-gray-25 hover:cursor-pointer': !item._active
            }
          )}
        >
          {item.name}
        </a>
      ))}
    </>
  )
}

function Menu() {
  const { data = [], isLoading } = useNavigation()
  return (
    <div
      className='flex flex-col space-y-2'
      style={{
        maxWidth: '215px'
      }}
    >
      <Search initialItems={data} />
      <div>
        <hr className='bg-darken-12 h-px mt-4 mb-2 p-0 border-0' />
      </div>
      {isLoading ? <SkeletonList /> : <List initialItems={data} />}
    </div>
  )
}

function CompactMenu() {
  const { data = [], isLoading } = useNavigation()

  const items = data.filter((item) => item.onMenu)

  const activeItem = items.find((item) => item._active)

  const { node } = usePortalEl()

  return (
    <>
      <div className='p-1'>
        <Search initialItems={data} />
      </div>
      <div className='p-1'>
        <MobileSecondaryNav
          title={activeItem?.name || 'Select a page...'}
          portalEl={node}
        >
          <HidePendoBadge />
          {isLoading ? (
            <SkeletonList />
          ) : (
            <>
              <SecondaryNavSection selected={activeItem?.pagePath}>
                {items?.map((item) => (
                  <SecondaryNavItem
                    title={item.name}
                    value={item.pagePath}
                    as='a' //esx-web applies unwanted css to all buttons, using an <a> got around the default button color
                    href={item.pageUrl}
                  />
                ))}
              </SecondaryNavSection>
            </>
          )}
        </MobileSecondaryNav>
      </div>
    </>
  )
}

function SkeletonList() {
  return (
    <div className='space-y-4'>
      {Array(10)
        .fill(0)
        .map(() => (
          <Skeleton className='w-full h-10' />
        ))}
    </div>
  )
}
