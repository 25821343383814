import * as React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import { client, rqClient } from '@local/api/client'
import { EntryRoute } from './routes'
import { QueryClientProvider } from '@tanstack/react-query'
import { PortalProvider } from '@toasttab/ec-layout'

//this is the whole app. the routes do 99% of the work
const App = () => useRoutes([EntryRoute])

/**
 * This is the full application
 */
const AppBootstrap = () => {
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={rqClient}>
        <BrowserRouter>
          <PortalProvider value='data-ec-settings-nav'>
            <React.Suspense fallback={null}>
              <App />
            </React.Suspense>
          </PortalProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ApolloProvider>
  )
}

export { App, AppBootstrap }
