import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'

const useIsMobile = () => {
  const screenSize = useScreenSize()

  return screenSize <= ScreenSize.MD
}

type Size = 'phone' | 'tablet' | 'desktop'

const useResponsiveSize = () => {
  const screenSize = useScreenSize()

  const size: Size =
    screenSize <= ScreenSize.XS
      ? 'phone'
      : screenSize <= ScreenSize.MD
      ? 'tablet'
      : 'desktop'

  return {
    size,
    isPhone: size === 'phone',
    isTablet: size === 'tablet',
    isDesktop: size === 'desktop',
    isTabletOrSmaller: screenSize <= ScreenSize.MD,
    isTabletOrLarger: screenSize > ScreenSize.XS
  }
}

export { useIsMobile, useResponsiveSize }
